// Fonts
//@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "bootstrap/bootstrap";


body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

header + div {
  flex: 1;
  display: flex;
  & > .navbar {
    align-items:flex-start ;
    max-width:180px;
    font-size:1.2em;

  }
}

div > main {
  flex: 1;
  padding: 1vw 1vw 0;
}

.atys-icon{
  display:flex;
  align-items:center;
  color:inherit;
}
.atys-icon svg{
  display:flex;
  align-items:center;
  opacity:.5;
  width:1.5em;
  height:1em;
  margin:0 0 0 0;
}

.sidebar  svg{
  fill:white;
  margin:0 .375em 0 0;
}
.sidebar .active  svg{
  opacity:.9;
}
h2.atys-icon svg{
  width:1.2em;
  height:1.2em;
  margin:0 .375em 0 0;

}

.text-success.atys-icon svg{
fill:rgb(42, 178, 123);
width:1.5em;
height:1.5em;
    &:first-child{
      display:flex;
    }
    &:last-child{
      display:none;
    }

}
.text-warning.atys-icon svg{
fill:rgb(203, 185, 86);
width:1.5em;
height:1.5em;
&:first-child{
  display:none;
}
&:last-child{
  display:flex;
}
}


// multiplete

div.awesomplete ul {
  column-width: 200px;
  column-gap: 0;
  font-size: 0.7em;
}

div.awesomplete ul li {
  padding: 0.2em;
}

.awesomplete li img {
  width: 100%;
  max-width: 100%;
}

.awesomplete span {
  // position: absolute;
  // bottom: 1.2em;
  // left: 0;
  // right: 0;
  // background-color: hsla(200,100%,100%,.5);
  // padding: 0.5em;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
.media--list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  align-items: stretch;

  input {
    position: absolute;
    clip: rect(0,0,0,0);
  }

  > li {
    margin: 0.2em;
    padding: 0 0.2em;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 0.2em;
    display: flex;
    flex-direction: column;
  }

  label {
    flex: 1;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-weight: 600;
    font-size: 1em;
  }

  img {
    max-width: 150px;
    padding: 0;
  }

  input:checked + label::before {
    content: '\2713';
    color: #d9534f;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    font-size: 4em;
    border-radius: 3em;
    vertical-align: top;
    width: 0.6em;
    height: 0.6em;
    line-height: 0.3em;
    text-shadow: 2px 2px 4px hsla(100,0,0%,.5);
  }
}

.bg-form{

  background-color: #ded;
  border: 1px solid #ccc;
  padding:$grid-gutter-width / 2;

}

/* cards dashboard */
.card--dashboard{
  text-align:center;
  max-width:500px;
  padding: 30px;
  border: 0;
  border-radius: 3px;
  flex: 1;
  margin: 10px;
  color:#fff;
  background-size: cover;
  background-position: center center;
  opacity:.9;
  .atys-icon svg{
    fill:#fff;
    width:4em;
    height:4em;
    margin: 0 auto 3em;
    opacity:1;
  }

  .atys-icon:before{
     background: rgba(0,0,0,.3);
     position:absolute;
     top:1.8em;
     left:calc(50% - 3.5em);
     border-radius: 50%;
    width:7em;
    height:7em;
    content: " ";
  }
}
.card--dashboard:hover{
  opacity:1;
}
.card--slideshow{
  background-image: linear-gradient(135deg,rgba(45,53,61,.79) 0,rgba(45,53,61,.5) 100%),url(/images/slideshows.jpg);
}
.card--devices{
  background-image: linear-gradient(135deg,rgba(45,53,61,.79) 0,rgba(45,53,61,.5) 100%),url(/images/devices.png);
}
.card--posters{
  background-image: linear-gradient(135deg,rgba(45,53,61,.79) 0,rgba(45,53,61,.5) 100%),url(/images/posters.jpg);
}
